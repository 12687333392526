import { Checkbox, FormControlLabel, Rating, TextField } from "@mui/material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useState } from "react";
import { useHistory } from "react-router-dom";
const RiderTripFeedback = ({ tripProgress, handleTripProgress }) => {
  const [rating, setRating] = useState("Very Good");
  const history = useHistory();
  const handleSelect = (e) => {
    setRating(e.target.value);
  };

  const [rideStatus, setRideStatus] = useState("");

  try {
    if (window.sessionStorage.getItem("bearerToken") === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  return (
    <div className="rider-profile-body">
      <div className="form-row-1">
        <p style={{ fontSize: 24, fontWeight: 600 }}>How is your trip going so far?</p>
        <div style={{ display: "flex", flexDirection: "row", gap: "10%" }}>
          <FormControlLabel
            key="Good"
            control={<Checkbox
              checked={tripProgress.rideStatus === "Good"}
              icon={<ThumbUpIcon fontSize="large" />}
              checkedIcon={<ThumbUpIcon fontSize="large" />} />}
            onChange={() => {
              // setRideStatus("Good");
              handleTripProgress("rideStatus", "Good");
            }}
          />
          <FormControlLabel
            key="Bad"
            control={<Checkbox
              checked={tripProgress.rideStatus === "Bad"}
              icon={<ThumbDownIcon fontSize="large" />}
              checkedIcon={<ThumbDownIcon fontSize="large" />} />}
            onChange={() => {
              // setRideStatus("Bad");
              handleTripProgress("rideStatus", "Bad");
            }}
          />
        </div>
        {/* <p style={{ fontWeight: 600, color: "#3579F6" }}>Feedback shared to time bank buddy</p> */}
      </div>
      {/* <div className="form-row-1">
        <p>How is the driver's car and driving skills</p>
        <Select className="text-component" value={rating} onChange={handleSelect}>
          <MenuItem value={"Very Good"}>Very Good</MenuItem>
          <MenuItem value={"Good"}>Good</MenuItem>
          <MenuItem value={"Average"}>Average</MenuItem>
          <MenuItem value={"Bad"}>Bad</MenuItem>
          <MenuItem value={"Very Bad"}>Very Bad</MenuItem>
        </Select>
      </div> */}
      <div className="form-row-1">
        <p>Message for your timebank buddy or <br></br> emergency contact or any safety concerns</p>
        <TextField className="text-component" hiddenLabel id="filled-hidden-label-small" size="small" placeholder="Add comments" value={tripProgress.message} onChange={(e) => { handleTripProgress("message", e.target.value); }} />
      </div>
    </div>
  );
}

export default RiderTripFeedback;