import { Button, IconButton, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Popup from "./Popup";
const DriverTripBuddyShare = ({id, riderID}) => {
  const [timeBankBuddy, setTimeBankBuddy] = useState("Olivia");
  const [rider, setRider] = useState({});
  const [tripRequest, setTripRequest] = useState({});
  const [tripSummary, setTripSummary] = useState("");
  const [riderContact, setRiderContact] = useState("");
  const [contactDetails, setContactDetails] = useState("");
  const [infoPopUp,setInfoPopUp] = useState(true);

  const handleSelect = (e) => {
    setTimeBankBuddy(e.target.value);
  };
  const handleContact = () => {
    return;
  };

  useEffect(() => {
    const getRider = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/rider/" + riderID)
        .then((response) => {
          setRider({
            ...response.data,
          });
          setContactDetails(`${response.data.profile.name} if needed at ${response.data.profile.email} or ${response.data.profile.mobile}`)
          setRiderContact(`with the rider ${response.data.profile.name} who can be contacted at ${response.data.profile.mobile}`);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
        });
    };
    getRider();
  }, []);


  useEffect(() => {
    const getTrip = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/trip-request/" + id)
        .then((response) => {
          setTripRequest({
            ...response.data,
          });

          setTripSummary(`I am offering a trip from ${response.data.pickup} to ${response.data.destination} on ${response.data.date}`);

          if (response.data.self_booking==="No"){
            setContactDetails(`${response.data.rider_details.name} if needed at ${response.data.rider_details.email} or ${response.data.rider_details.mobile}`)
            setRiderContact(`with the rider ${response.data.rider_details.name} who can be contacted at ${response.data.rider_details.mobile}`);
          }
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
        });
    };
    getTrip();
  }, [contactDetails,riderContact]);

  return (
    <div className="rider-profile-body">
      {tripRequest.self_booking==="No" && (<Popup openPopup={infoPopUp}
            >
              <div>
              <p>
                This ride request was made by an organizational member. 
                <br></br>
                Please contact the rider directly to determine specific details for the trip.
                <br></br><br></br>
                Details you will want to confirm include: 
                <br></br>
                <ol type="a">
                  <li>Pick-up location</li>
                  <li>Pick-up time</li>
                  <li>Number of hours needed for the trip</li>
                  <li>If additional stops are needed</li>
                  <li>Number of people travelling</li>
                  <li>If the person has any allergies</li>
                  <li>If they prefer that both of you wear a mask</li>
                  <li>If they can provide gas money</li>
                  <li>If there are any items that they need to transport?</li>
                  <li>Name and number of their emergency contact.</li>  
                </ol>
              </p>
                <div
                  style={{
                    paddingTop: "2rem",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setInfoPopUp(false);
                    }}
                    style={{
                      color: "black",
                      background: "#091E4206",
                      textTransform: "none",
                    }}
                  >
                    Okay
                  </Button>
                </div>
              </div>
            </Popup>)}
      <div className="form-row-1">
        <p style={{ fontSize: 24, fontWeight: 600 }}>Start Your Trip</p>
        {/* <p style={{ color: "#3579F6" }}>Share Link</p> */}
      </div>
      <div>
        <p style={{ fontSize: 16, fontWeight: 600 }}>Trip Summary    <IconButton color="primary" onClick={() => { navigator.clipboard.writeText(tripSummary + riderContact) }}><ContentCopyIcon /></IconButton></p>
        <p>{tripSummary} {riderContact}</p>
        <p><i>Copy and paste the ride details into an email or text</i></p>
      </div>
      <div>
        <p style={{ fontSize: 16, fontWeight: 600 }}>
          Contact Details
        </p><br></br>
        <p>Please reach out to the rider {contactDetails}</p>
      </div>
      <div>
        <p>All set for the trip if you are done with the preparation</p>
        <br></br>
        <p>Go ahead and start your trip to record your hours</p>
      </div>
      {/* <p style={{ fontSize: 16, fontWeight: 600 }}>
        Share status to time bank buddy
      </p>
      <div className="form-row-1">
        <p>Share live updates to your time bank buddy</p>
        <Select
          className="text-component"
          value={timeBankBuddy}
          onChange={handleSelect}
        >
          <MenuItem value={"Olivia"}>Olivia</MenuItem>
          <MenuItem value={"Melissa"}>Melissa</MenuItem>
        </Select>
      </div>
      <p style={{ fontSize: 16, fontWeight: 600 }}>My emergency contacts</p>
      <p>1. John Doe, +1 1234567890, jdoe@gmail.com</p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          onClick={handleContact}
          style={{ color: "black", background: "#091E4206" }}
        >
          Add emergency contact
        </Button>
      </div> */}
    </div>
  );
};

export default DriverTripBuddyShare;
