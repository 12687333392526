import { Button } from "@mui/material";
import Rating from "@mui/material/Rating";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactRoundedImage from "react-rounded-image";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";

const DriverPublicProfile = () => {
  const [apiError, setAPIError] = useState(false);
  const history = useHistory();
  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }
  const userID = window.sessionStorage.getItem("userID");
  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;


  const queryParams = new URLSearchParams(window.location.search);
  const driverID = queryParams.get('driverID');

  const [profile, setProfile] = useState({});
  const [reviews, setReviews] = useState([]);
  const [reviewElements, setReviewElements] = useState([]);
  const [covidPreference, setCovidPreference] = useState("");
  const [languages, setLanguages] = useState("");


  useEffect(() => {
    setReviewElements([]);
    const getDriverProfile = async () => {
      await axios
        .get(
          "https://iuhw-app.hourworld.org/driver/" +
          driverID +
          "/profile"
        )
        .then((response) => {
          setProfile({
            ...response.data.profile,
          });

          setReviews(response.data.reviews);

          let covid = "";
          if (response.data.profile.covid.wearMask === true) {
            covid += "I will wear a mask, ";
          }
          if (response.data.profile.covid.riderWearMask === true) {
            covid += "I want the rider to wear a mask, ";
          }

          if (covid !== "") {
            covid = covid.slice(0, -2);
          }else{
            covid = "None"
          }

          setCovidPreference(covid);

          let languages = "";
          if (response.data.profile.languages.english === true) {
            languages += "English, ";
          }
          if (response.data.profile.languages.arabic === true) {
            languages += "Arabic, ";
          }
          if (response.data.profile.languages.spanish === true) {
            languages += "Spanish, ";
          }

          if (response.data.profile.languages.other === "") {
            languages = languages.slice(0, -2);
          } else {
            languages += response.data.profile.languages.other
          }

          setLanguages(languages);

          let revs = [];
          for (let i = 0; i < response.data.reviews.length; i++) {
            revs.push(
              <div
                key={response.data.reviews[i].id}
                className="driver-info-box"
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "70%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    {/* <ReactRoundedImage
                      image={require("../images/avatar_female.jpg")}
                      roundedSize="0"
                      imageWidth="50"
                      imageHeight="50"
                    /> */}
                    <p style={{ fontWeight: "600" }}>
                      {response.data.reviews[i].name}
                    </p>
                  </div>
                  <div>
                    <Rating
                      name="driver-rating"
                      defaultValue={2.5}
                      value={response.data.reviews[i].rating}
                      precision={0.5}
                      sx={{
                        '& .MuiRating-icon': {
                          fontSize: '2rem',
                        },
                      }}
                      readOnly
                    />{" "}
                  </div>
                </div>
                <br></br>
                <p><b>Comment: </b>{response.data.reviews[i].review}</p>
                <br></br>
                <p>The driver felt {response.data.reviews[i].emotion} about the trip</p>
                <br></br>
                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                  <p><b>Date:</b> {response.data.reviews[i].date}</p>
                  <p><b>Time:</b> {response.data.reviews[i].time}</p>
                </div>
              </div>,
              <br></br>
            );
          }
          setReviewElements(revs);
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getDriverProfile();
  }, []);

  return (
    <div className="profile">
      <div className="body" style={{ paddingBottom: "20px" }}>
        <p className="heading" style={{ fontWeight: "bold" }}>
          {profile.name}'s Public Profile
        </p>
      </div>
      <div>
        <div
          className="split-body-equal-left-unpadded"
          style={{ paddingLeft: "1rem", paddingBottom: "2rem" }}
        >
          <div style={{ paddingBottom: "3rem" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <ReactRoundedImage
                image={profile.image}
                roundedSize="0"
                imageWidth="60"
                imageHeight="60"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <p style={{ fontWeight: "600" }}>{profile.name}</p>
                <p style={{ fontWeight: "600" }}>{profile.pronoun}</p>
                {userID === driverID && (<a href="" style={{ textDecoration: "none" }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#3579F6",
                    }}
                    onClick={() => { history.push(`/edit-driver-profile`) }}
                  >
                    Edit Profile
                  </p>
                </a>)}
              </div>
            </div>
            <br />
            <br />

            <div className="form-row">
              <div className="form-column-1">
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {profile.rating}/5
                </p>
                <p style={{ fontSize: "16px" }}>ratings</p>
              </div>
              <div className="form-column-1">
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {profile.reviews}
                </p>
                <p style={{ fontSize: "16px" }}>reviews</p>
              </div>
              <div className="form-column-1">
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {profile.totalRides}
                </p>
                <p style={{ fontSize: "16px" }}>Total trips</p>
              </div>
              <div className="form-column-1">
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {profile.years}
                </p>
                <p style={{ fontSize: "16px" }}>years</p>
              </div>
            </div>
            <br></br>
            <br></br>
            <div className="form-row">
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Health-related safety preferences
                </p>
                <br></br>
                <p>{covidPreference}</p>
                <br></br>
              </div>
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Smoking preferences
                </p>
                <br></br>
                <p>{profile.smoke}</p>
                <br></br>
              </div>
            </div>
            <div className="form-row">
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Allergies
                </p>
                <br></br>
                <p>{profile.allergies}</p>
                <br></br>
              </div>
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Spoken Languages
                </p>
                <br></br>
                <p>{languages}</p>
                <br></br>
              </div>
            </div>
            {/* <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <Button
                variant="contained"
                onClick={addFriend}
                style={{
                  color: "black",
                  background: "#091E4206",
                  textTransform: "none",
                }}
              >
                Add friend
              </Button>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  background: "#0C66E4",
                  textTransform: "none",
                }}
                onClick={requestTrip}
              >
                Request for a trip
              </Button>
            </div> */}

          </div>
          <div>
            {/* <img
              src={profile.vehicleImage}
              style={{ width: "20rem" }}
            />
            <br></br> */}
            {/* <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <img
                src={require("../images/car1.jpg")}
                style={{ width: "5rem" }}
              />
              <img
                src={require("../images/car1.jpg")}
                style={{ width: "5rem" }}
              />
            </div> */}
            <br></br>
            <p style={{ fontWeight: "600" }}>Vehicle Information</p>
            <br />
            <p>
              {profile.make}, {profile.model} {profile.vehicleYear} - {profile.specification}
            </p>
            <br />
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: "3rem" }}>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "2rem",
          }}
        >
          Timebank training certificate
        </p>
        {profile.certificateDate == null && (
          <p>
            Please complete your driver training course to obtain a certificate
          </p>
        )}
        {profile.certificateDate != null && (<div className="driver-info-box">
          <div style={{ display: "flex", flexDirection: "row", gap: "50%" }}>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              Completed on {profile.certificateDate}
            </p>
            {/* <Button
              variant="contained"
              style={{
                color: "white",
                background: "#0C66E4",
                textTransform: "none",
              }}
            >
              View certificate
            </Button> */}
          </div>
          <br></br>
          <p>
            This is to certify that {profile.name} has completed the hour-world's 2-hour
            driver training course.
          </p>
          <br></br>
          {/* <p>
            <b>Certificate expiry: </b>
            {profile.certificateExpiryDate}
          </p> */}
        </div>)}
      </div>
      <div className="form-column">
        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
          {profile.name}'s reviews
        </p>
        {reviewElements.length == 0 && <p>No reviews found</p>}
        {reviewElements.length != 0 && <div>{reviewElements}</div>}
      </div>
      {apiError && (
        <Alert severity="error">
          Request to server failed. Please try again
        </Alert>
      )}
    </div>
  );
};

export default DriverPublicProfile;
