import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import RiderProfile from "./components/RiderProfile";
import DriverHomePage from "./components/driverHomePage";
import DriverProfile from "./components/driverProfile";
import DriverWelcomePage from "./components/driverWelcomePage";
import DriverPublicProfile from "./components/driverPublicProfile";
import RiderPublicProfile from "./components/riderPublicProfile";
import AvailableDrivers from "./components/availableDrivers";
import RiderChecklist from "./components/riderChecklist";
import DriverChecklist from "./components/driverChecklist";
import RiderMyTrips from "./components/riderMyTrips";
import CreateTripRequest from "./components/createTripRequest";
import RiderTripComplete from "./components/riderTripComplete";
import DriverProfilePreview from "./components/driverProfilePreview";
import DriverMyTrips from "./components/driverMyTrips";
import DriverTripRequests from "./components/driverTripRequests";
import DriverTripComplete from "./components/driverTripComplete";
import DriverAchievements from "./components/driverAchievements";
import Login from "./components/login";
import RiderProfileUpdate from "./components/riderProfileUpdate";
import DriverProfileUpdate from "./components/driverProfileUpdate";

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/rider-my-trips">
              <RiderMyTrips />
            </Route>
            <Route exact path="/rider-profile">
              <RiderProfile />
            </Route>
            <Route exact path="/edit-rider-profile">
              <RiderProfileUpdate />
            </Route>
            <Route exact path="/rider-public-profile">
              <RiderPublicProfile />
            </Route>
            <Route exact path="/create-trip-request">
              <CreateTripRequest />
            </Route>
            <Route exact path="/rider-trip/:id/complete">
              <RiderTripComplete />
            </Route>
            <Route exact path="/driver-home">
              <DriverHomePage />
            </Route>
            <Route exact path="/driver-welcome">
              <DriverWelcomePage />
            </Route>
            <Route exact path="/driver-profile">
              <DriverProfile />
            </Route>
            <Route exact path="/edit-driver-profile">
              <DriverProfileUpdate />
            </Route>
            <Route exact path="/driver-my-trips">
              <DriverMyTrips />
            </Route>
            <Route exact path="/driver-profile-preview">
              <DriverProfilePreview />
            </Route>
            <Route exact path="/driver-trip-requests">
              <DriverTripRequests />
            </Route>
            <Route exact path="/driver-trip/:id/complete">
              <DriverTripComplete />
            </Route>
            <Route exact path="/driver-achievements">
              <DriverAchievements />
            </Route>
            <Route exact path="/driver-public-profile">
              <DriverPublicProfile />
            </Route>
            <Route exact path="/trip-request/:id/drivers">
              <AvailableDrivers />
            </Route>
            <Route exact path="/trip/:id/rider-checklist">
              <RiderChecklist />
            </Route>
            <Route exact path="/trip/:id/driver-checklist">
              <DriverChecklist />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
