import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Popup from "./Popup";
import axios from "axios";
import { useHistory } from "react-router-dom";

const DriverPreparation = ({ riderID }) => {
  const [routeChecked, setRouteChecked] = useState(false);
  const [maskChecked, setMaskChecked] = useState(false);
  const [seatChecked, setSeatChecked] = useState(false);
  const [parkingChecked, setParkingChecked] = useState(false);
  const [appointmentChecked, setAppointmentChecked] = useState(false);
  const [riderTaskPopUp, setRiderTaskPopup] = useState(false);
  const [driverTaskPopUp, setDriverTaskPopup] = useState(false);
  const [markPopUp, setMarkPopup] = useState(false);
  let driverID = 0;

  const [riderList, setRiderList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [apiError, setAPIError] = useState(false);
  let dList = [];
  let rList = [];
  let riderWearMask = false;
  let driverWearMask = false;
  let driverNewTask, riderNewTask;
  const history = useHistory();

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  try {
    driverID = window.sessionStorage.getItem("driverID");
  } catch (e) {
    //TODO: Alert
    console.log("Failed to get driver ID");
  }

  const openRiderTaskPopup = () => {
    setRiderTaskPopup(true);
  };

  const closeRiderTaskPopup = () => {
    setRiderTaskPopup(false);
  };

  const openDriverTaskPopup = () => {
    setDriverTaskPopup(true);
  };

  const closeDriverTaskPopup = () => {
    setDriverTaskPopup(false);
  };

  const openMarkPopup = () => {
    setMarkPopup(true);
  };

  const closeMarkPopup = () => {
    setMarkPopup(false);
  };

  const handleRouteChange = (e) => {
    setRouteChecked(e.target.checked);
  };

  const handleMaskChange = (e) => {
    setMaskChecked(e.target.checked);
  };

  const handleSeatChange = (e) => {
    setSeatChecked(e.target.checked);
  };

  const handleParkingChange = (e) => {
    setParkingChecked(e.target.checked);
  };

  const handleAppointmentChange = (e) => {
    setAppointmentChecked(e.target.checked);
  };

  const handleSubmit = () => {
    return;
  };

  const handleMarking = () => {
    return;
  };

  const handleNewDriverTask = () => {
    const newElement = (
      <div key={Math.random().toString(36).substring(7)}>
        <FormControlLabel
          control={<Checkbox  />}
          label={driverNewTask}
        />
        <br></br>
      </div>
    );
    setDriverList((driverList) => [...driverList, newElement]);
    closeDriverTaskPopup();
    return;
  };

  const handleNewRiderTask = () => {
    const newElement = (
      <div key={Math.random().toString(36).substring(7)}>
        <FormControlLabel control={<Checkbox disabled/>} label={riderNewTask} />
        <br></br>
      </div>
    );
    setRiderList((riderList) => [...riderList, newElement]);
    closeRiderTaskPopup();
    return;
  };

  useEffect(() => {
    const getRiderProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/rider/" + riderID)
        .then((response) => {
          if (
            response.data.preferences.covid.wearMask === true &&
            riderWearMask === false
          ) {
            riderWearMask = true;
            rList.push(
              <FormControlLabel
                key="wearMask"
                control={<Checkbox disabled />}
                label="Wear a face mask"
              />,
              <br></br>
            );
          }
          if (
            response.data.preferences.covid.driverWearMask === true &&
            driverWearMask === false
          ) {
            driverWearMask = true;
            dList.push(
              <FormControlLabel
                key="driverWearMask"
                control={<Checkbox defaultChecked  />}
                label="Wear a face mask"
              />,
              <br></br>
            );
          }

          if (response.data.vehicle_assistance === "Yes") {
            dList.push(
              <FormControlLabel
                key="vehicleAssistance"
                control={<Checkbox defaultChecked  />}
                label="Arrange required assistance"
              />,
              <br></br>
            );
          }

          setDriverList(dList);
          setRiderList(rList);
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getRiderProfile();
  }, []);

  useEffect(() => {
    const getDriverProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/driver/" + driverID)
        .then((response) => {
          if (
            response.data.preferences.covid.wearMask === true &&
            driverWearMask === false
          ) {
            driverWearMask = true;
            dList.push(
              <FormControlLabel
                key="dWearMask"
                control={<Checkbox />}
                label="Wear a face mask"
              />,
              <br></br>
            );
          }
          if (
            response.data.preferences.covid.riderWearMask === true &&
            riderWearMask === false
          ) {
            riderWearMask = true;
            rList.push(
              <FormControlLabel
                key="rWearMask"
                // control={<Checkbox defaultChecked  />}
                control={<Checkbox disabled />}
                label="Wear a face mask"
              />,
              <br></br>
            );
          }

          setDriverList(dList);
          setRiderList(rList);
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getDriverProfile();
  }, []);

  return (
    <div className="rider-profile-body">
      <p style={{ fontSize: 24, fontWeight: 600 }}>Trip Preparation Checklist</p>
      <div className="driver-split">
        <div>
          <div className="driver-info-box">
            <p style={{ fontWeight: "600" }}>
              Driver's Checklist to complete before the trip
            </p>
            <br></br>
            <div style={{ paddingLeft: "2rem" }}>
              {driverList}
              <FormControlLabel
                control={<Checkbox defaultChecked  />}
                label="Check gas in vehicle"
              />
              <br></br>
              <FormControlLabel
                control={<Checkbox defaultChecked  />}
                label="Check tread on tires"
              />
              <br></br>
              <FormControlLabel
                control={<Checkbox />}
                label="Check headlights and taillights"
              />
              <br></br>
              <FormControlLabel
                control={<Checkbox />}
                label="Check window wiper fluid levels"
              />
              <br></br>
              <FormControlLabel
                control={<Checkbox />}
                label="Agree on gas money"
              />
            </div>
            <br></br>
            <Button onClick={openDriverTaskPopup} style={{ textTransform: "none" }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "#3579F6",
                }}
              >
                Add task
              </p>
            </Button>
            <br></br>
            <br></br>
            {/* <Button
              variant="contained"
              onClick={openMarkPopup}
              style={{
                color: "white",
                background: "#0C66E4",
                textTransform: "none",
              }}
            >
              Mark all done
            </Button> */}
          </div>
        </div>
        <Popup openPopup={driverTaskPopUp}>
          <div>
            <p className="heading"> Add task to the checklist</p>
            <br></br>
            <p>The person will be notified of the new task</p>
            <br></br>
            <TextField
              className="text-component"
              hiddenLabel
              id="filled-hidden-label-small"
              size="small"
              placeholder="Add task"
              value={driverNewTask}
              onChange={(e) => {
                driverNewTask = e.target.value;
              }}
            />
            <div
              style={{
                paddingTop: "3rem",
                display: "flex",
                flexDirection: "row",
                gap: "50%",
              }}
            >
              <Button
                variant="contained"
                onClick={closeDriverTaskPopup}
                style={{
                  color: "black",
                  background: "#091E4206",
                  textTransform: "none",
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  background: "#0C66E4",
                  textTransform: "none",
                }}
                onClick={handleNewDriverTask}
              >
                Add Task
              </Button>
            </div>
          </div>
        </Popup>
        <Popup
          openPopup={markPopUp}
        >
          <div>
            <p className="heading"> Completed the checklist?</p>
            <br></br>
            <p>Are you sure you wish to start the trip without fulfilling the checklist</p><br></br>
            <div
              style={{
                paddingTop: "3rem",
                display: "flex",
                flexDirection: "row",
                gap: "50%",
              }}
            >
              <Button
                variant="contained"
                onClick={closeMarkPopup}
                style={{
                  color: "black",
                  background: "#091E4206",
                  textTransform: "none",
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  background: "#0C66E4",
                  textTransform: "none",
                }}
                onClick={handleMarking}
              >
                Yes, I understand
              </Button>
            </div>
          </div>
        </Popup>
        <div>
          <div className="driver-info-box">
            <p style={{ fontWeight: "600" }}>Rider's Checklist</p>
            <br></br>
            <div style={{ paddingLeft: "2rem" }}>
              <FormControlLabel
                // control={<Checkbox  />}
                control={<Checkbox disabled />}
                label="Confirm Appointment"
                onChange={handleAppointmentChange}
              />
              <br></br>
              <FormControlLabel
                control={<Checkbox disabled />}
                label="Agree on gas money"
              />
              <br></br>
              <FormControlLabel
                control={<Checkbox disabled />}
                label="Confirm parking location"
              />
              <br></br>
              {riderList}
            </div>
            <br></br>
            <Button onClick={openRiderTaskPopup} style={{ textTransform: "none" }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "#3579F6",
                }}
              >
                Add task
              </p>
            </Button>
            <Popup openPopup={riderTaskPopUp}>
              <div>
                <p className="heading"> Add task to the checklist</p>
                <br></br>
                <p>The person will be notified of the new task</p>
                <br></br>
                <TextField
                  className="text-component"
                  hiddenLabel
                  id="filled-hidden-label-small"
                  size="small"
                  placeholder="Add task"
                  value={riderNewTask}
                  onChange={(e) => {
                    riderNewTask = e.target.value;
                  }}
                />
                <div
                  style={{
                    paddingTop: "3rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "50%",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={closeRiderTaskPopup}
                    style={{
                      color: "black",
                      background: "#091E4206",
                      textTransform: "none",
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      color: "white",
                      background: "#0C66E4",
                      textTransform: "none",
                    }}
                    onClick={handleNewRiderTask}
                  >
                    Add Task
                  </Button>
                </div>
              </div>
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverPreparation;
