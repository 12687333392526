import { useEffect, useState } from "react";
import { Tab, Tabs, TextField } from "@mui/material";
import { Button } from "@mui/material";
import ReactRoundedImage from "react-rounded-image";
import { useHistory } from "react-router-dom";
import Popup from "./Popup";
import axios from "axios";
import Alert from "@mui/material/Alert";
import ScheduleIcon from '@mui/icons-material/Schedule';
const RiderMyTrips = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tripCancellationReason, setCancellationReason] = useState("");
  const history = useHistory();
  const [popUp, setPopup] = useState(false);
  const [tripID, setTripID] = useState(0);
  // const [tripRequest, setTripRequest] = useState({});
  let riderID;
  const statusMap = new Map();

  statusMap.set("Requested", "Waiting for a driver to respond to your trip request");
  statusMap.set("Requested_Driver", "Waiting for a driver to respond to your trip request");
  statusMap.set("Accepted", "Trip request has been accepted");
  statusMap.set("Completed", "Trip request has been fulfilled");
  statusMap.set("Cancelled", "Trip request has been cancelled");

  const [apiError, setAPIError] = useState(false);

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  try {
    riderID = window.sessionStorage.getItem("riderID");
  } catch (e) {
    //TODO: Alert
    console.log("Failed to get rider ID");
  }

  const [profile, setProfile] = useState({});
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [preferences, setPreferences] = useState({});
  const [emergencyContactElements, setEmergencyContactElements] = useState([]);
  const [upcomingTrips, setUpcomingTrips] = useState([]);
  const [upcomingTripElements, setUpcomingTripElements] = useState([]);
  const [pastTrips, setPastTrips] = useState([]);
  const [pastTripElements, setPastTripElements] = useState([]);
  const [allTrips, setAllTrips] = useState([]);
  const [tripElements, setTripElements] = useState([]);
  const [covidPreference, setCovidPreference] = useState("");
  const [languages, setLanguages] = useState("");

  useEffect(() => {
    setEmergencyContactElements([]);
    const getRiderProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/rider/" + riderID)
        .then((response) => {
          setProfile({
            ...response.data.profile,
          });
          setEmergencyContacts(response.data.emergencyContacts);
          setPreferences({
            ...response.data.preferences,
          });

          let covid = "";
          if (response.data.preferences.covid.wearMask === true) {
            covid += "I will wear a mask, ";
          }
          if (response.data.preferences.covid.driverWearMask === true) {
            covid += "I want the driver to wear a mask, ";
          }

          if (covid !== "") {
            covid = covid.slice(0, -2);
          }else{
            covid = "None"
          }

          setCovidPreference(covid);

          let languages = "";
          if (response.data.preferences.languages.english === true) {
            languages += "English, ";
          }
          if (response.data.preferences.languages.arabic === true) {
            languages += "Arabic, ";
          }
          if (response.data.preferences.languages.spanish === true) {
            languages += "Spanish, ";
          }

          if (response.data.preferences.languages.other === "") {
            languages = languages.slice(0, -2);
          } else {
            languages += response.data.preferences.languages.other
          }

          setLanguages(languages);

          let contacts = [];
          for (let i = 0; i < response.data.emergencyContacts.length; i++) {
            contacts.push(
              <div key={response.data.emergencyContacts[i].id}>
                {response.data.emergencyContacts[i].name}
                <br></br>
                {response.data.emergencyContacts[i].mobile}
                <br></br>
                {response.data.emergencyContacts[i].email}
              </div>,
              <br></br>
            );
          }

          // window.sessionStorage.setItem(
          //   "riderProfile",
          //   JSON.stringify(response.data)
          // );

          setEmergencyContactElements(contacts);
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getRiderProfile();
  }, []);

  useEffect(() => {
    setUpcomingTripElements([]);
    const getUpcomingTrips = async () => {
      await axios
        .get(
          "https://iuhw-app.hourworld.org/rider/" +
          riderID +
          "/trips?type=upcoming"
        )
        .then((response) => {
          setUpcomingTrips(response.data);
          let trips = [];
          for (let i = 0; i < response.data.length; i++) {
            const currentDate = new Date();
            const tripCreatedDate = new Date(response.data[i].createdAt);
            const diff = (currentDate.getTime() - tripCreatedDate.getTime()) / 36e5;
            trips.push(
              <div key={response.data[i].id} className="driver-info-box">
                {/* <div
                  style={{ display: "flex", alignItems: "center", gap: "70%" }}
                > */}
                {/* <p>{response.data[i].date}</p>
                  <p style={{ display: "flex", alignItems: "center" }}>{response.data[i].time} {diff > 24 && <ScheduleIcon style={{ marginLeft: 4 }} />}</p> */}
                {/* </div>
                <br></br> */}
                {/* TODO: Handle destination  */}
                <p style={{ fontWeight: "600" }}>
                  {response.data[i].pickup} to {response.data[i].destination}  {diff > 24 && <ScheduleIcon style={{ marginLeft: 4 }} />}
                </p>
                <br></br>
                <p>Status: {statusMap.get(response.data[i].status)}</p>
                <br></br>
                {response.data[i].timebank_buddy=="Yes" && (<><p>Timebank buddy matchup: Requested</p><br></br></>)}
                <p>Pickup point: {response.data[i].pickup}</p>
                <br></br>
                <p>Nearby Intersection: {response.data[i].nearby_intersection}</p>
                <br></br>
                <p>Destination: {response.data[i].destination}</p>
                <br></br>
                <p>Date of Appointment: {response.data[i].date}</p>
                <br></br>
                <p>Pickup time: {response.data[i].time}</p>
                <br></br>
                <p>Trip time: {response.data[i].trip_time} hours</p>
                <br />
                {/* <p>Purpose: {response.data[i].purpose}</p>
                <br></br> */}
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20%" }}
                >
                  {response.data[i].self_booking==="Yes" && response.data[i].assignedDriverID !== 0
                    //  && response.data[i].rideStarted===true 
                    && <Button
                      variant="contained"
                      style={{
                        color: "white",
                        background: "#0C66E4",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        handleViewChecklist(response.data[i].id, response.data[i].assignedDriverID);
                      }}
                    >
                      View Checklist
                    </Button>}
                  {response.data[i].assignedDriverID !== 0 && <Button
                    variant="contained"
                    style={{
                      color: "white",
                      background: "green",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      handleStartTrip(response.data[i].id, response.data[i].assignedDriverID);
                    }}
                  >
                    Start trip
                  </Button>
                  }
                  {response.data[i].assignedDriverID === 0 && response.data[i].status !== "Accepted" && <Button
                    variant="contained"
                    style={{
                      color: "white",
                      background: "#0C66E4",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      history.push(
                        `/trip-request/${response.data[i].id}/drivers`
                      );
                    }}
                  >
                    View Drivers
                  </Button>}
                  <Button
                    onClick={() => {
                      setTripID(response.data[i].id);
                      openPopup();
                    }}
                    variant="contained"
                    style={{
                      textTransform: "none",
                      color: "black",
                      background: "#F8f9fa",
                    }}
                  >
                    Cancel trip
                  </Button>
                </div>
              </div>,
              <br></br>
            );
          }
          setUpcomingTripElements(trips);
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getUpcomingTrips();
  }, []);

  useEffect(() => {
    setPastTripElements([]);
    const getPastTrips = async () => {
      await axios
        .get(
          "https://iuhw-app.hourworld.org/rider/" +
          riderID +
          "/trips?type=past"
        )
        .then((response) => {
          setPastTrips(response.data);
          let trips = [];
          for (let i = 0; i < response.data.length; i++) {
            trips.push(
              <div key={response.data[i].id} className="driver-info-box">
                {/* <div
                  style={{ display: "flex", alignItems: "center", gap: "70%" }}
                >
                  <p>{response.data[i].date}</p>
                  <p>{response.data[i].time}</p>
                </div> */}
                {/* <br></br> */}
                {/* TODO: Handle destination  */}
                <p style={{ fontWeight: "600" }}>
                  {response.data[i].pickup} to {response.data[i].destination}
                </p>
                <br></br>
                <p>Status: {statusMap.get(response.data[i].status)}</p>
                <br></br>
                {response.data[i].timebank_buddy=="Yes" && (<><p>Timebank buddy matchup: Requested</p><br></br></>)}
                <p>Pickup point: {response.data[i].pickup}</p>
                <br></br>
                <p>Nearby Pickup Intersection: {response.data[i].nearby_intersection}</p>
                <br></br>
                <p>Destination: {response.data[i].destination}</p>
                <br></br>
                <p>Date of Appointment: {response.data[i].date}</p>
                <br></br>
                <p>Pickup time: {response.data[i].time}</p>
                <br></br>
                <p>Trip time: {response.data[i].trip_time} hours</p>
                <br />
                {/* <p>Purpose: {response.data[i].purpose}</p>
                <br></br> */}
                {/* <div
                  style={{ display: "flex", alignItems: "center", gap: "50%" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      color: "white",
                      background: "#0C66E4",
                      textTransform: "none",
                    }}
                    onClick={()=>{history.push(`/trip-request/${response.data[i].id}/drivers`);}}
                  >
                    View Driver
                  </Button>
                </div> */}
              </div>,
              <br></br>
            );
          }
          setPastTripElements(trips);
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getPastTrips();
  }, []);

  useEffect(() => {
    setTripElements([]);
    const getTrips = async () => {
      await axios
        .get(
          "https://iuhw-app.hourworld.org/rider/" +
          riderID +
          "/trips?type="
        )
        .then((response) => {
          setAllTrips(response.data);
          let trips = [];
          for (let i = 0; i < response.data.length; i++) {
            trips.push(
              <div key={response.data[i].id} className="driver-info-box">
                {/* <div
                  style={{ display: "flex", alignItems: "center", gap: "30%" }}
                >
                  <p>Date of appointment: {response.data[i].date}</p>
                  <p>Pickup time: {response.data[i].time}</p>
                </div> */}
                {/* <br></br> */}
                {/* TODO: Handle destination  */}
                <p style={{ fontWeight: "600" }}>
                  {response.data[i].pickup} to {response.data[i].destination}
                </p>
                <br></br>
                <p>Status: {statusMap.get(response.data[i].status)}</p>
                <br></br>
                {response.data[i].timebank_buddy=="Yes" && (<><p>Timebank buddy matchup: Requested</p><br></br></>)}
                <p>Pickup point: {response.data[i].pickup}</p>
                <br></br>
                <p>Nearby Pickup Intersection: {response.data[i].nearby_intersection}</p>
                <br></br>
                <p>Destination: {response.data[i].destination}</p>
                <br></br>
                <p>Date of Appointment: {response.data[i].date}</p>
                <br></br>
                <p>Pickup time: {response.data[i].time}</p>
                <br></br>
                <p>Trip time: {response.data[i].trip_time} hours</p>
                <br />
                {/* <p>Purpose: {response.data[i].purpose}</p>
                <br></br> */}
                <div
                  style={{ display: "flex", alignItems: "center", gap: "50%" }}
                >
                  {response.data[i].status !== "Cancelled" && <Button
                    variant="contained"
                    style={{
                      color: "white",
                      background: "#0C66E4",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      history.push(
                        `/trip-request/${response.data[i].id}/drivers`
                      );
                    }}
                  >
                    View Driver
                  </Button>}
                </div>
              </div>,
              <br></br>
            );
          }
          setTripElements(trips);
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getTrips();
  }, []);

  const openPopup = () => {
    setPopup(true);
  };

  const closePopup = () => {
    setPopup(false);
  };

  const addRequest = () => {
    history.push("/create-trip-request");
  };

  const handleCancelTrip = async () => {
    // await axios
    //     .get("https://iuhw-app.hourworld.org/trip-request/" + tripID)
    //     .then((response) => {
    //       setTripRequest({
    //         ...response.data,
    //       });
    //     })
    //     .catch((e) => {
    //       //TODO: Add Alert
    //       console.log(e);
    //     });
    // tripRequest.status="Cancelled";
    await axios
      .put(
        "https://iuhw-app.hourworld.org/trip-request/" + tripID,
        {
          status: "Cancelled",
          additionalComments: tripCancellationReason,
        }
      )
      .then(() => {
        console.log("Cancelled trip request");
        closePopup();
        window.location.reload(true);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
      });
  };

  const handleViewChecklist = (id, driverID) => {
    if (driverID===null){
      return null;
    }

    history.push(`/trip/${id}/rider-checklist?driverID=${driverID}`);
  };

  const handleStartTrip = (id, driverID) => {
    if (driverID===null){
      return null;
    }
    
    history.push(`/trip/${id}/rider-checklist?driverID=${driverID}&page=1`);
  };

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="profile">
      <div className="body">
        <p className="heading" style={{ fontWeight: "bold" }}>
          My Trips
        </p>
      </div>

      <div className="split-body">
        <div>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="Upcoming Trips" style={{ textTransform: "None" }}></Tab>
            <Tab label="Trip requests" style={{ textTransform: "None" }}></Tab>
            <Tab label="Trip history" style={{ textTransform: "None" }}></Tab>
          </Tabs>

          {selectedTab === 0 && (
            <div>
              <div className="split-column">
                <p style={{ fontSize: 24, fontWeight: 600 }}>
                  My Upcoming Trips
                </p>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    background: "#0C66E4",
                    textTransform: "none",
                  }}
                  onClick={addRequest}
                >
                  Request a trip
                </Button>
              </div>
              <br></br>
              {/* Follow this if a key warning throwsup at other places */}
              {upcomingTripElements}
              <Popup openPopup={popUp}>
                <div>
                  <p className="heading"> Do you want to cancel the trip?</p>
                  <br></br>
                  <p>Are you sure you want to cancel the trip?</p>
                  <br></br>
                  <p>State the reason for trip cancellation?</p>
                  <TextField
                    className="text-component"
                    hiddenLabel
                    id="filled-hidden-label-small"
                    size="small"
                    placeholder="Reason"
                    value={tripCancellationReason}
                    onChange={(e) => {
                      setCancellationReason(e.target.value);
                    }}
                  />
                  <div
                    style={{
                      paddingTop: "3rem",
                      display: "flex",
                      flexDirection: "row",
                      gap: "50%",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={closePopup}
                      style={{
                        color: "black",
                        background: "#091E4206",
                        textTransform: "none",
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        background: "#0C66E4",
                        textTransform: "none",
                      }}
                      onClick={handleCancelTrip}
                    >
                      Cancel trip
                    </Button>
                  </div>
                </div>
              </Popup>
              <br />
              <div className="driver-info-box">
                <p style={{ fontWeight: "600" }}>
                  Your trip requests will be displayed here
                </p>
                <br></br>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    background: "#0C66E4",
                    textTransform: "none",
                  }}
                  onClick={addRequest}
                >
                  Request a trip
                </Button>
              </div>
            </div>
          )}

          {selectedTab === 1 && (
            <div>
              <div className="split-column">
                <p style={{ fontSize: 24, fontWeight: 600 }}>My Trip Request</p>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    background: "#0C66E4",
                    textTransform: "none",
                  }}
                  onClick={addRequest}
                >
                  Request a trip
                </Button>
              </div>
              <br />
              <div>{tripElements}</div>
              <br></br>
              <div className="driver-info-box">
                <p style={{ fontWeight: "600" }}>
                  Your trip requests will be displayed here
                </p>
                <br></br>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    background: "#0C66E4",
                    textTransform: "none",
                  }}
                  onClick={addRequest}
                >
                  Request a trip
                </Button>
              </div>
            </div>
          )}

          {selectedTab === 2 && (
            <div>
              <div className="split-column">
                <p style={{ fontSize: 24, fontWeight: 600 }}>My Trip History</p>
              </div>
              <br></br>
              <div>{pastTripElements}</div>
            </div>
          )}
        </div>
        <div>
          <p>Please review our privacy policy <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/Privacy.pdf">here</a> and terms of service <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/TOS.pdf">here</a></p><br></br>
          <p><i>This ride service is part of National Science Foundation funded study and is approved by the University of Michigan institutional review board # HUM00166267</i></p>
          <div className="split-column">
            <p style={{ fontSize: 24, fontWeight: 600 }}>My Profile</p>
            <Button style={{ textTransform: "none" }} onClick={() => { history.push(`/edit-rider-profile`) }}>
              <p style={{ fontWeight: "bold", color: "#3579F6" }}>
                Edit Profile
              </p>
            </Button>
          </div>
          <div className="driver-info-box-1">
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <ReactRoundedImage
                image={profile.image}
                roundedSize="0"
                imageWidth="50"
                imageHeight="50"
              />
              <p style={{ fontWeight: "600" }}>{profile.name}</p>
            </div>
            <br></br>
            <p>
              Hi! I am {profile.name} from {profile.location}
            </p>
            <br></br>
            <p style={{ fontWeight: "600" }}>My preferences</p>
            <br />
            <p>Spoken Languages: {languages}</p>
            <br />
            <p>Health-related safety preferences: {covidPreference}</p>
            <br />
            <p>Smoking preferences: {preferences.smoke}</p>
            <br />
            {/* <p>Vehicle assistance: {preferences.vehicle_assistance}</p>
            <br /> */}
            <p>
              Allergies: {preferences.allergies}
            </p>
            <br />
            <p>Music: {preferences.music}</p>
            <br />
            <br />
            <div className="form-row">
              <p style={{ fontWeight: "600", paddingTop: "10px" }}>
                Emergency Contact
              </p>
              {/* <Button style={{ textTransform: "none" }}>
                <p style={{ fontWeight: "bold", color: "#3579F6" }}>
                  Edit Contact
                </p>
              </Button> */}
            </div>
            <br />
            {emergencyContactElements}
            <br></br>
            {/* <Button disableElevation
              variant="contained"
              style={{
                textTransform: "none",
                color: "black",
                background: "#F8f9fa",
              }}
            >
              Add Emergency Contact
            </Button> */}
          </div>
        </div>
      </div>
      {apiError && (
        <Alert severity="error">
          Request to server failed. Please try again
        </Alert>
      )}
    </div>
  );
};

export default RiderMyTrips;
