import { useHistory } from "react-router-dom";
import Card from "./card";
import { useEffect, useState } from "react";
import axios from "axios";

const Home = () => {
  const history = useHistory();
  const [riderRoute, setRiderRoute] = useState("/rider-profile");
  const [driverRoute, setDriverRoute] = useState("/driver-home");
  const [timebank, setTimeBank] = useState("");
  const [eID, seteID]= useState(1060);
  const [memID, setmemID]= useState(0);
  const [userID, setuserID]= useState(0);
  const [bearerToken,setBearerToken]= useState("");

  
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    seteID(queryParams.get("eID"));

    const sToken = window.sessionStorage.getItem("bearerToken");

    if (sToken!==null){
      seteID(window.sessionStorage.getItem("eID"));
      setmemID(window.sessionStorage.getItem("memID"));
      setuserID(window.sessionStorage.getItem("userID"));
      setBearerToken(sToken);

      return;
    }

    setmemID(queryParams.get("memID"));
    setuserID(queryParams.get("userID"));
    const bToken=queryParams.get("bearerToken");
    setBearerToken(bToken);        

 
      if (bToken === null || bToken === "") {
        history.push(`/login?eID=${eID}`);
      } else {
        window.sessionStorage.setItem("userID", queryParams.get("userID"))
        window.sessionStorage.setItem("eID", queryParams.get("eID"))
        window.sessionStorage.setItem("memID", queryParams.get("memID"))
        window.sessionStorage.setItem("bearerToken", bToken)
      }
  }, []);

  useEffect(() => {
    const getTimeBank = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/time-bank?eID=" + eID)
        .then((response) => {
          setTimeBank(response.data.timebank);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    getTimeBank();
  }, [eID]);

  useEffect(() => {
    const getRiderProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/rider/" + userID + "/profile", { headers: { "Authorization": "Bearer " + bearerToken } })
        .then((response) => {
          if (response.data.profile !== null) {
            window.sessionStorage.setItem("riderID", response.data.profile.id);
            setRiderRoute("/create-trip-request");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    getRiderProfile();
  }, [userID, bearerToken]);

  useEffect(() => {
    const getDriverProfile = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/driver/" + userID + "/profile", { headers: { "Authorization": "Bearer " + bearerToken } })
        .then((response) => {
          if (response.data.profile !== null) {
            window.sessionStorage.setItem("driverID", response.data.profile.id);
            setDriverRoute("/driver-my-trips");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    getDriverProfile();
  }, [userID, bearerToken]);

  return (
    <div className="home">
      <div className="welcome">
        <p className="heading">Hour Exchange: {timebank}</p>
        <br />
        <p className="subHeading">
          Helping neighbors build community one hour at a time
        </p>
      </div>
      <div className="content">
        <p className="heading" style={{ fontWeight: "bold" }}>
          {/* Hello {user}! */}
          Hello!
        </p>
        <br />
        <p className="subHeading">
          How would you like to use the ride-sharing service?
        </p>
      </div>
      <div className="wrapper">
        <Card
          image={require("../images/rider.png")}
          title={"Request a ride"}
          description={
            "Request for trip services from the members of the timebank"
          }
          submit={"Request"}
          route={riderRoute}
        />
        <Card
          image={require("../images/driver.png")}
          title="Make a ride offer"
          description="Help timebank members with trips"
          submit="Offer"
          route={driverRoute}
        />
      </div>
    </div>
  );
};

export default Home;
