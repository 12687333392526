import { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import RiderPreparation from "./riderPreparation";
import RiderBuddyShare from "./riderBuddyShare";
import RiderTripFeedback from "./RiderTripFeedback";
import { useHistory } from "react-router-dom";
import Popup from "./Popup";
import axios from "axios";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
const RiderChecklist = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const driverID = queryParams.get('driverID');
  const pageParam = queryParams.get("page");

  let p = parseInt(pageParam);
  if (isNaN(p)) {
    p = 0;
  };

  const [page, setPage] = useState(p);
  const [nextButton, setNextButton] = useState("Next");
  const history = useHistory();
  const [tripPopUp, setTripPopup] = useState(false);
  const [timePopUp, setTimePopup] = useState(false);
  const [tripDetails, setTripDetails] = useState({});
  const [tripStartTime, setTripStartTime] = useState();
  const [tripTime, setTripTime] = useState("");
  const [apiError, setAPIError] = useState(false);
  const [tripRequest, setTripRequest] = useState({});
  const currentDate = new Date();
  const [tripTimeError, setTripTimeError]=useState(false);
  currentDate.setHours(currentDate.getHours() - 4);
  const { id } = useParams();

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  const [tripProgress, setTripProgress] = useState({
    id: id,
    tripShare: "Yes",
    emergencyContactBuddy: 0,
    tripBuddy: "",
    mobile: "",
    rideStatus: "",
    message: "",
    riderID: 0,
  });

  function handleTripProgress(field, value) {
    setTripProgress({
      ...tripProgress,
      [field]: value,
    });
  }

  const openTripPopup = () => {
    setTripPopup(true);
  };

  const closeTripPopup = () => {
    setTripPopup(false);
  };

  const openTimePopup = () => {
    setTimePopup(true);
  };

  const closeTimePopup = () => {
    setTimePopup(false);
  };

  const FormTitles = [
    "Trip Preparation Checklist",
    "Share trip details",
    "Trip Complete",
  ];

  useEffect(() => {
    const getTrip = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/trip-request/" + id)
        .then((response) => {
          setTripRequest({
            ...response.data,
          });
          setAPIError(false);

          if (response.data.self_booking==="No"){
            setPage(1);
          }
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getTrip();
  }, []);

  const PageDisplay = () => {
    if (page === 0) {
      if (tripRequest.self_booking==="No"){
        handleNext();
        setNextButton("Start Trip");
      }
      return <RiderPreparation driverID={driverID} />;
    } else if (page === 1) {
      return <RiderBuddyShare id={id} driverID={driverID} tripProgress={tripProgress} handleTripProgress={handleTripProgress} />;
    } else {
      return <RiderTripFeedback tripProgress={tripProgress} handleTripProgress={handleTripProgress} />;
    }
  };

  const handleBack = () => {
    setPage((currPage) => currPage - 1);
    if (page === 2) {
      setNextButton("Start Trip");
    } else if (page === 1) {
      setNextButton("Next");
    }
  };

  const handleNext = () => {
    if (page === 1) {
      setTripPopup(true);
      return;
    } else if (page === 2) {
      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() - 4);

      setTripTime(((currentDate.getTime() - tripStartTime.getTime()) / 36e5).toFixed(3));

      setTimePopup(true);
      return;
    }

    setPage((currPage) => currPage + 1);
    // if (page === 2) {
    //   history.push(`/rider-trip/${id}/complete?driverID=${driverID}`);
    // }

    if (page === 0) {
      setNextButton("Start Trip");
    } else {
      setNextButton("Next");
    }
  };

  const handleStartTrip = async () => {
    await axios
      .put(
        "https://iuhw-app.hourworld.org/trip-request/" + id,
        {
          status: "Started",
        }
      )
      .then(() => {
        console.log("Updated trip request");
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });

    await axios
      .get("https://iuhw-app.hourworld.org/trip-details/" + id + "?driverID=" + driverID)
      .then((response) => {
        setTripDetails({
          ...response.data,
        });
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 4);
    setTripStartTime(currentDate);

    tripDetails.status = "Started";
    tripDetails.startTime = currentDate.toUTCString();
    tripDetails.driverID = driverID;

    await axios
      .put(
        "https://iuhw-app.hourworld.org/trip-details/" + id,
        tripDetails
      )
      .then(() => {
        console.log("Updated trip details");
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });
    setTripPopup(false);
    setPage((currPage) => currPage + 1);
    setNextButton("Complete Trip");
  };

  const handleTripComplete = async () => {
    if (tripTimeError || tripTime<=0 || tripTime>24){
      return;
    }
    
    setTimePopup(false);
    await axios
      .put(
        "https://iuhw-app.hourworld.org/trip-request/" + id,
        {
          status: "Completed",
        }
      )
      .then(() => {
        console.log("Updated trip request");
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });

    await axios
      .get("https://iuhw-app.hourworld.org/trip-details/" + id + "?driverID=" + driverID)
      .then((response) => {
        setTripDetails({
          ...response.data,
        });
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 4);

    tripDetails.status = "Completed";
    tripDetails.completionTime = currentDate.toUTCString();
    tripDetails.tripTime = tripTime;
    tripDetails.driverID = driverID;

    await axios
      .put(
        "https://iuhw-app.hourworld.org/trip-details/" + id,
        tripDetails
      )
      .then(() => {
        console.log("Updated trip details");
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });

    await axios
      .post(
        "https://iuhw-app.hourworld.org/rider/" + tripProgress.riderID + "/trip/" + id + "/progress",
        tripProgress
      )
      .then(() => {
        setAPIError(false);
      })
      .catch((e) => {
        console.log(e);
      });

    history.push(`/rider-trip/${id}/complete?driverID=${driverID}`);
  };

  return (
    <div className="profile">
      <div className="body">
        <p className="heading" style={{ fontWeight: "bold" }}>
          Upcoming Trip
        </p>
        {/* <br />
        <p className="subHeading">
          Trip preparation checklist, complete the following checklist before your trip
        </p> */}
      </div>

      <div className="profile-home">
        <div className="driver-progressbar">
          <div
            style={{
              width: page === 0 ? "33%" : page === 1 ? "66%" : "85%",
            }}
          ></div>
        </div>
        <div className="inline-text">
          <div
            style={{
              color: page === 0 ? "#3579F6" : "black",
              marginLeft: "0%",
            }}
          >
            {FormTitles[0]}
          </div>
          <div
            style={{
              color: page === 1 ? "#3579F6" : "black",
              marginLeft: "15%",
            }}
          >
            {FormTitles[1]}
          </div>
          <div
            style={{
              color: page === 2 ? "#3579F6" : "black",
              marginLeft: "15%",
            }}
          >
            {FormTitles[2]}
          </div>
        </div>
      </div>
      <div className="form-container">
        <div className="form-body">{PageDisplay()}</div>
        <div className="form-footer">
          <Button
            variant="contained"
            disabled={page === 0}
            onClick={handleBack}
            style={{
              color: "black",
              background: "#091E4206",
              textTransform: "none",
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            style={{
              color: "white",
              background: "#0C66E4",
              textTransform: "none",
            }}
          >
            {nextButton}
          </Button>
        </div>
      </div>
      <Popup openPopup={tripPopUp}>
        <div>
          <p className="heading"> Starting the trip?</p>
          <br></br>
          <p>
          Both the rider and driver have to the start the trip in order for the ride to be recorded in the timebank. 
          Please log back in when you are ready to start the trip. 
          </p>
          <br></br>
          <p>
            <b>Start time: </b>{currentDate.toUTCString()}
          </p>
          <div
            style={{
              paddingTop: "3rem",
              display: "flex",
              flexDirection: "row",
              gap: "50%",
            }}
          >
            <Button
              variant="contained"
              onClick={closeTripPopup}
              style={{
                color: "black",
                background: "#091E4206",
                textTransform: "none",
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={{
                color: "white",
                background: "#0C66E4",
                textTransform: "none",
              }}
              onClick={handleStartTrip}
            >
              Start Trip
            </Button>
          </div>
        </div>
      </Popup>
      <Popup openPopup={timePopUp}>
        <div>
          <p className="heading">Verify the total time-bank hours</p>
          <br></br>
          <p>Discuss the total trip hours and verify it below</p>
          <br></br>
          <TextField
            error={tripTimeError}
            helperText={tripTimeError ? "Please enter a valid trip duration" : ''}
            className="text-component"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            placeholder="Hours"
            value={tripTime}
            onChange={(e) => {
              if (parseInt(e.target.value)<=0 || parseInt(e.target.value)>24){
                setTripTimeError(true);
                return;
              }else{
                setTripTimeError(false);
              }
              setTripTime(e.target.value);
            }}
          />
          <br></br>
          <p>Please remember to log your hours at <a target="_blank" rel="noopener noreferrer" href="https://www.hourworld.org/">hOurworld</a></p>
          <br></br>
          <div
            style={{
              paddingTop: "3rem",
              display: "flex",
              flexDirection: "row",
              gap: "50%",
            }}
          >
            <Button
              variant="contained"
              onClick={closeTimePopup}
              style={{
                color: "black",
                background: "#091E4206",
                textTransform: "none",
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={{
                color: "white",
                background: "#0C66E4",
                textTransform: "none",
              }}
              onClick={handleTripComplete}
            >
              Submit
            </Button>
          </div>
        </div>
      </Popup>
      {apiError && (
        <Alert severity="error">
          Request to server failed. Please try again
        </Alert>
      )}
    </div>
  );
};

export default RiderChecklist;
