import { Button, Tab, Tabs } from "@mui/material";
import ReactRoundedImage from "react-rounded-image";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Popup from "./Popup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useParams } from "react-router-dom";
import axios from "axios";
import Alert from "@mui/material/Alert";

const AvailableDrivers = () => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const [acceptPopUp, setAcceptPopup] = useState(false);
  const [requestPopUp, setRequestPopUp] = useState([]);
  const [favRequestPopUp, setFavRequestPopUp] = useState([]);
  const [tripRequest, setTripRequest] = useState({});
  const { id } = useParams();

  const riderID = window.sessionStorage.getItem("riderID");
  const [apiError, setAPIError] = useState(false);
  const [selfError, setSelfError] = useState(false);

  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  useEffect(() => {
    const getTrip = async () => {
      await axios
        .get("https://iuhw-app.hourworld.org/trip-request/" + id)
        .then((response) => {
          setTripRequest({
            ...response.data,
          });
          setAPIError(false);
        })
        .catch((e) => {
          //TODO: Add Alert
          console.log(e);
          setAPIError(true);
        });
    };
    getTrip();
  }, []);

  const openAcceptedPopup = async (index, driverID) => {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 4);
    await axios
      .put("https://iuhw-app.hourworld.org/trip-details/" + id, {
        id: id,
        status: "Accepted",
        driverID: driverID,
      })
      .then(() => {
        setAPIError(false);
      })
      .catch((e) => {
        console.log(e);
        setAPIError(true);
      });

    await axios
      .put("https://iuhw-app.hourworld.org/trip-request/" + id, {
        status: "Accepted",
      })
      .then(() => {
        const updated = [...acceptPopUp];
        updated[index] = true;
        setAcceptPopup(updated);
        console.log("Accepted");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const openRequestPopup = (index) => {
    const updated = [...requestPopUp];
    updated[index] = true;
    setRequestPopUp(updated);
  };

  const closeRequestPopup = (index) => {
    const updated = [...requestPopUp];
    updated[index] = false;
    setRequestPopUp(updated);
  };

  const closeAcceptPopup = (index) => {
    const updated = [...acceptPopUp];
    updated[index] = false;
    setAcceptPopup(updated);
  };

  const openFavRequestPopup = (index) => {
    const updated = [...favRequestPopUp];
    updated[index] = true;
    setFavRequestPopUp(updated);
  };

  const closeFavRequestPopup = (index) => {
    const updated = [...favRequestPopUp];
    updated[index] = false;
    setFavRequestPopUp(updated);
  };

  const handleChecklist = (driverID) => {
    if (driverID===null){
      return;
    }

    history.push(`/trip/${id}/rider-checklist?driverID=${driverID}`);
  };

  const handleDismiss = () => {
    history.push("/home");
  };

  const handleTripRequest = async (idx, driverID, closeRequest) => {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 4);
    await axios
      .post("https://iuhw-app.hourworld.org/trip-details", {
        id: id,
        status: "Requested",
        driverID: driverID,
      })
      .then(() => {
        setAPIError(false);
      })
      .catch((e) => {
        console.log(e);
        setAPIError(true);
      });

    await axios
      .put("https://iuhw-app.hourworld.org/trip-request/" + id, {
        status: "Requested_Driver",
      })
      .then(() => {
        const index = idx;
        closeRequest(index);
        setAPIError(false);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        setAPIError(true);
      });
    history.push("/rider-my-trips");
  };

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const [acceptedDrivers, setAcceptedDrivers] = useState([]);

  const [nearbyDrivers, setNearbyDrivers] = useState([]);

  const [favoriteDrivers, setFavouriteDrivers] = useState([]);

  // const [finalDrivers, setFinalDrivers] = useState([]);

  useEffect(() => {
    const getAcceptedDrivers = async () => {
      try {
        let accepted_drivers = [];
        await axios
          .get(
            "https://iuhw-app.hourworld.org/trip-request/" +
            id +
            "/drivers?type=accepted"
          )
          .then((response) => {
            accepted_drivers = response.data;
            setAPIError(false);
          });
        setAcceptedDrivers(accepted_drivers);
        const initial = accepted_drivers.map(() => false);
        setAcceptPopup(initial);
      } catch (error) {
        console.error("Error fetching data:", error);
        setAPIError(true);
      }
    };

    getAcceptedDrivers();
  }, []);

  // useEffect(() => {
  //   const getFinalDrivers = async () => {
  //     try {
  //       let final_drivers = [];
  //       await axios
  //         .get(
  //           "https://iuhw-app.hourworld.org/trip-request/" +
  //             id +
  //             "/drivers?type=completed"
  //         )
  //         .then((response) => {
  //           final_drivers = response.data;
  //           setAPIError(false);
  //         });
  //       setFinalDrivers(final_drivers);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setAPIError(true);
  //     }
  //   };

  //   getFinalDrivers();
  // }, []);

  useEffect(() => {
    const getNearbyDrivers = async () => {
      try {
        let drivers = [];
        await axios
          .get(
            "https://iuhw-app.hourworld.org/trip-request/" +
            id +
            "/drivers?type="
          )
          .then((response) => {
            drivers = response.data;
            setAPIError(false);
          });
        setNearbyDrivers(drivers);
        const initial = drivers.map(() => false);
        setRequestPopUp(initial);
      } catch (error) {
        console.error("Error fetching data:", error);
        setAPIError(true);
      }
    };

    getNearbyDrivers();
  }, []);

  useEffect(() => {
    const getFavouriteDrivers = async () => {
      try {
        let fav_drivers = [];
        await axios
          .get(
            "https://iuhw-app.hourworld.org/rider/" + riderID + "/favourites"
          )
          .then((response) => {
            fav_drivers = response.data;
            setAPIError(false);
          });
        setFavouriteDrivers(fav_drivers);
        const initial = fav_drivers.map(() => false);
        setFavRequestPopUp(initial);
      } catch (error) {
        console.error("Error fetching data:", error);
        setAPIError(true);
      }
    };

    getFavouriteDrivers();
  }, []);


  return (
    <div className="profile">
      <div className="body" style={{ paddingBottom: "20px" }}>
        <p className="heading" style={{ fontWeight: "bold" }}>
          Drivers
        </p>
        <br />
        <p className="subHeading">
          View accepted trip requests and other available drivers.
        </p>
      </div>

      <Tabs value={selectedTab} onChange={handleTabChange}>
        {/* {tripRequest.status==="Completed" && <Tab label="Trip Driver" style={{ textTransform: "None" }}></Tab>} */}
        <Tab label="Accepted Drivers" style={{ textTransform: "None" }}></Tab>
        {tripRequest.status !== "Completed" && (
          <Tab
            label="Available Drivers"
            style={{ textTransform: "None" }}
          ></Tab>
        )}
        {tripRequest.status !== "Completed" && (<Tab label="Favorite Drivers" style={{ textTransform: "None" }}></Tab>)}
        {/* <Tab label="Frequent drivers" style={{ textTransform: "None" }}></Tab> */}
      </Tabs>

      {/* {selectedTab === 0 && tripRequest.status==="Completed"  &&(
        <div>
          {finalDrivers.map((driver, index) => {
            let covid = "";
            if (driver.profile.covid.wearMask === true) {
              covid += "I will wear a mask, ";
            }
            if (driver.profile.covid.riderWearMask === true) {
              covid += "I want the rider to wear a mask, ";
            }

            if (covid !== "") {
              covid = covid.slice(0, -2);
            }

            return (
              <div key={driver.profile.id}>
                <div className="driver-box">
                  <div
                    className="split-body-equal-left-unpadded"
                    style={{ paddingLeft: "1rem", paddingBottom: "2rem" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <ReactRoundedImage
                          image={driver.profile.image}
                          roundedSize="0"
                          imageWidth="60"
                          imageHeight="60"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <p style={{ fontWeight: "600" }}>
                            {driver.profile.name}
                          </p>
                          <a href="" style={{ textDecoration: "none" }}>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#3579F6",
                              }}
                            >
                              View Profile
                            </p>
                          </a>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="form-row">
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {driver.profile.totalRides}
                          </p>
                          <p style={{ fontSize: "16px" }}>Total rides</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {driver.profile.years}
                          </p>
                          <p style={{ fontSize: "16px" }}>years</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {driver.profile.reviews}
                          </p>
                          <p style={{ fontSize: "16px" }}>reviews</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {driver.profile.rating}
                          </p>
                          <p style={{ fontSize: "16px" }}>ratings</p>
                        </div>
                      </div>
                      <br></br>
                      <br></br>
                      <div className="form-row">
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Health-related safety preferences
                          </p>
                          <br></br>
                          <p>{covid}</p>
                          <br></br>
                        </div>
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Smoking preferences
                          </p>
                          <br></br>
                          <p>{driver.profile.smoke}</p>
                          <br></br>
                        </div>
                      </div>
                      <div>
                        <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                          Allergies
                        </p>
                        <br></br>
                        <p>{driver.profile.allergies}</p>
                        <br></br>
                      </div>
                    </div>
                    <div>
                      <img
                        src={driver.profile.vehicleImage}
                        style={{ width: "20rem" }}
                      />
                      <br></br>
                      <br></br>
                      <p style={{ fontWeight: "600" }}>Vehicle Information</p>
                      <br />
                      <p>
                        {driver.profile.make} {driver.profile.model} -{" "}
                        {driver.profile.specifications}
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <br></br>
              </div>
            );
          })}
        </div>
      )} */}

      {selectedTab === 0 && (
        <div>
          {acceptedDrivers.map((driver, index) => {
            let covid = "";
            if (driver.profile.covid.wearMask === true) {
              covid += "I will wear a mask, ";
            }
            if (driver.profile.covid.riderWearMask === true) {
              covid += "I want the rider to wear a mask, ";
            }

            if (covid !== "") {
              covid = covid.slice(0, -2);
            }else{
              covid = "None"
            }

            let languages = "";
            if (driver.profile.languages.english === true) {
              languages += "English, ";
            }
            if (driver.profile.languages.arabic === true) {
              languages += "Arabic, ";
            }
            if (driver.profile.languages.spanish === true) {
              languages += "Spanish, ";
            }

            if (driver.profile.languages.other === "") {
              languages = languages.slice(0, -2);
            } else {
              languages += driver.profile.languages.other
            }

            return (
              <div key={driver.profile.id}>
                <div className="driver-box">
                  <div
                    className="split-body-equal-left-unpadded"
                    style={{ paddingLeft: "1rem", paddingBottom: "2rem" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <ReactRoundedImage
                          image={driver.profile.image}
                          roundedSize="0"
                          imageWidth="60"
                          imageHeight="60"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <p style={{ fontWeight: "600" }}>
                            {driver.profile.name}
                          </p>
                          {/* <Button style={{ textTransform: "none" }}> */}
                          <a href="" style={{ textDecoration: "none" }}>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#3579F6",
                              }}
                              onClick={()=>{history.push(`/driver-public-profile?driverID=${driver.profile.id}`)}}
                            >
                              View Profile
                            </p>
                          </a>
                          {/* </Button> */}
                        </div>
                      </div>
                      {/* <br></br> */}
                      {/* <Rating
                      name="driver-rating"
                      defaultValue={2.5}
                      value={4.0}
                      readOnly
                      iconSize="large"
                    />{" "}
                    4.0 */}
                      <br />
                      <br />
                      <div className="form-row">
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {driver.profile.totalRides}
                          </p>
                          <p style={{ fontSize: "16px" }}>Total rides</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {driver.profile.years}
                          </p>
                          <p style={{ fontSize: "16px" }}>years</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {driver.profile.reviews}
                          </p>
                          <p style={{ fontSize: "16px" }}>reviews</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {driver.profile.rating}
                          </p>
                          <p style={{ fontSize: "16px" }}>ratings</p>
                        </div>
                      </div>
                      <br></br>
                      <br></br>
                      {driver.profile.certificateDate!==null && (<p><i>Completed timebank training course and obtained certificate on {driver.profile.certificateDate}</i></p>)}
                      <br></br>
                      <br></br>
                      <div className="form-row">
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Health-related safety preferences
                          </p>
                          <br></br>
                          <p>{covid}</p>
                          <br></br>
                        </div>
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Smoking preferences
                          </p>
                          <br></br>
                          <p>{driver.profile.smoke}</p>
                          <br></br>
                        </div>
                      </div>
                      <div className="form-row">
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Allergies
                          </p>
                          <br></br>
                          <p>{driver.profile.allergies}</p>
                          <br></br>
                        </div>
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Spoken Languages
                          </p>
                          <br></br>
                          <p>{languages}</p>
                          <br></br>
                        </div>
                      </div>
                      {(tripRequest.status !== "Completed" && tripRequest.status !== "Accepted") && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                          }}
                        >
                          {/* <Button
                            variant="contained"
                            onClick={handleDismiss}
                            style={{
                              color: "black",
                              background: "#091E4206",
                              textTransform: "none",
                            }}
                          >
                            Dismiss
                          </Button> */}
                          <Button
                            variant="contained"
                            style={{
                              color: "white",
                              background: "#0C66E4",
                              textTransform: "none",
                            }}
                            onClick={() => {
                              openAcceptedPopup(index, driver.profile.id);
                            }}
                          >
                            Accept
                          </Button>
                          {/* <Button style={{ textTransform: "none" }}>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#3579F6",
                              }}
                            >
                              Chat with driver
                            </p>
                          </Button> */}
                        </div>
                      )}
                      <Popup
                        key={driver.profile.id}
                        openPopup={acceptPopUp[index]}
                      >
                        <div>
                          <div className="form-row">
                            <CheckCircleIcon sx={{ color: "green" }} />
                            <p className="heading"> Trip accepted!</p>
                          </div>
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              paddingTop: "1rem",
                              paddingBottom: "3rem",
                            }}
                          >
                            {" "}
                            Start trip checklist and proceed with your current
                            trip
                            {/*TODO: Get Trip Details and trip request ID passed  */}
                          </p>
                          <div className="driver-box">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "70%",
                              }}
                            >
                              <p>{tripRequest.date}</p>
                              <p>{tripRequest.time}</p>
                            </div>
                            <br></br>
                            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                              {tripRequest.pickup} to {tripRequest.destination}
                            </p>
                            <br></br>
                            <p>Nearby Pickup Intersection: {tripRequest.nearby_intersection}</p>
                            {/* <br></br>
                            <p> {tripRequest.purpose}</p> */}
                          </div>
                          <div
                            style={{
                              paddingTop: "5rem",
                              display: "flex",
                              flexDirection: "row",
                              gap: "50%",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                closeAcceptPopup(index);
                              }}
                              style={{
                                color: "black",
                                background: "#091E4206",
                                textTransform: "none",
                              }}
                            >
                              Close
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                background: "#0C66E4",
                                textTransform: "none",
                              }}
                              onClick={()=>{handleChecklist(driver.profile.id)}}
                            >
                              Start Preparation Checklist
                            </Button>
                          </div>
                        </div>
                      </Popup>
                    </div>
                    <div>
                      <img
                        src={driver.profile.vehicleImage}
                        style={{ width: "20rem" }}
                      />
                      {/* <br></br> */}
                      {driver.profile.vehicleImage!=="" && <p><i>Image uploaded on {driver.profile.vehicleUploadDate}</i></p>}
                      <br></br>
                      <br></br>
                      <p style={{ fontWeight: "600" }}>Vehicle Information</p>
                      <br />
                      <p>
                        {driver.profile.make} {driver.profile.model} {driver.profile.vehicleYear} -{" "}
                        {driver.profile.specification}
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <br></br>
              </div>
            );
          })}
        </div>
      )}

      {selectedTab === 1 && tripRequest.status !== "Completed" && (
        <div>
          {selfError && (
            <Alert severity="error">
              You cannot accept your trip request. Please wait for others to accept.
            </Alert>
          )}
          {nearbyDrivers.map((nearby, idx) => {
            let covid = "";
            if (nearby.profile.covid.wearMask === true) {
              covid += "I will wear a mask, ";
            }
            if (nearby.profile.covid.riderWearMask === true) {
              covid += "I want the rider to wear a mask, ";
            }

            if (covid !== "") {
              covid = covid.slice(0, -2);
            }else{
              covid = "None"
            }

            let languages = "";
            if (nearby.profile.languages.english === true) {
              languages += "English, ";
            }
            if (nearby.profile.languages.arabic === true) {
              languages += "Arabic, ";
            }
            if (nearby.profile.languages.spanish === true) {
              languages += "Spanish, ";
            }

            if (nearby.profile.languages.other === "") {
              languages = languages.slice(0, -2);
            } else {
              languages += nearby.profile.languages.other
            }

            return (
              <div key={nearby.profile.id}>
                <div className="driver-box">
                  <div
                    className="split-body-equal-left-unpadded"
                    style={{ paddingLeft: "1rem", paddingBottom: "2rem" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <ReactRoundedImage
                          image={nearby.profile.image}
                          roundedSize="0"
                          imageWidth="60"
                          imageHeight="60"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <p style={{ fontWeight: "600" }}>
                            {nearby.profile.name}
                          </p>
                          {/* <Button style={{ textTransform: "none" }}> */}
                          <a href="" style={{ textDecoration: "none" }}>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#3579F6",
                              }}
                              onClick={()=>{history.push(`/driver-public-profile?driverID=${nearby.profile.id}`)}}
                            >
                              View Profile
                            </p>
                          </a>
                          {/* </Button> */}
                        </div>
                      </div>
                      {/* <br></br> */}
                      {/* <Rating
                      name="driver-rating"
                      defaultValue={2.5}
                      value={4.0}
                      readOnly
                      iconSize="large"
                    />{" "}
                    4.0 */}
                      <br />
                      <br />
                      <div className="form-row">
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {nearby.profile.totalRides}
                          </p>
                          <p style={{ fontSize: "16px" }}>Total rides</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {nearby.profile.years}
                          </p>
                          <p style={{ fontSize: "16px" }}>years</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {nearby.profile.reviews}
                          </p>
                          <p style={{ fontSize: "16px" }}>reviews</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {nearby.profile.rating}
                          </p>
                          <p style={{ fontSize: "16px" }}>ratings</p>
                        </div>
                      </div>
                      <br></br>
                      <br></br>
                      {nearby.profile.certificateDate!==null && (<p><i>Completed timebank training course and obtained certificate on {nearby.profile.certificateDate}</i></p>)}
                      <br></br>
                      <br></br>
                      <div className="form-row">
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Health-related safety preferences
                          </p>
                          <br></br>
                          <p>{covid}</p>
                          <br></br>
                        </div>
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Smoking preferences
                          </p>
                          <br></br>
                          <p>{nearby.profile.smoke}</p>
                          <br></br>
                        </div>
                      </div>
                      <div className="form-row">
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Allergies
                          </p>
                          <br></br>
                          <p>{nearby.profile.allergies}</p>
                          <br></br>
                        </div>
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Spoken Languages
                          </p>
                          <br></br>
                          <p>{languages}</p>
                          <br></br>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                        }}
                      >
                        {/* <Button
                          variant="contained"
                          onClick={handleDismiss}
                          style={{
                            color: "black",
                            background: "#091E4206",
                            textTransform: "none",
                          }}
                        >
                          Dismiss
                        </Button> */}
                        {tripRequest.status !== "Accepted" && <Button
                          variant="contained"
                          style={{
                            color: "white",
                            background: "#0C66E4",
                            textTransform: "none",
                          }}
                          onClick={() => {
                            setSelfError(false);
                            if (nearby.profile.id.toString() === riderID) {
                              setSelfError(true);
                              return;
                            }
                            openRequestPopup(idx);
                          }}
                        >
                          Request for a ride
                        </Button>}
                      </div>
                      <Popup
                        key={nearby.profile.id}
                        openPopup={requestPopUp[idx]}
                      >
                        <div>
                          <p className="heading">
                            {" "}
                            Request {nearby.profile.name} for a ride
                          </p>
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              paddingTop: "1rem",
                              paddingBottom: "3rem",
                            }}
                          >
                            {" "}
                            Check your desired trip and send request
                          </p>
                          <div className="driver-box">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "70%",
                              }}
                            >
                              <p>{tripRequest.date}</p>
                              <p>{tripRequest.time}</p>
                            </div>
                            <br></br>
                            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                              {/* TODO: */}
                              {tripRequest.pickup} to {tripRequest.destination}
                            </p>
                            <br></br>
                            <p>Nearby Pickup Intersection: {tripRequest.nearby_intersection}</p>
                            {/* <br></br>
                            <p> {tripRequest.purpose}</p> */}
                          </div>
                          <div
                            style={{
                              paddingTop: "5rem",
                              display: "flex",
                              flexDirection: "row",
                              gap: "60%",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                closeRequestPopup(idx);
                              }}
                              style={{
                                color: "black",
                                background: "#091E4206",
                                textTransform: "none",
                              }}
                            >
                              Close
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                background: "#0C66E4",
                                textTransform: "none",
                              }}
                              onClick={() => {
                                handleTripRequest(idx, nearby.profile.id, closeRequestPopup);
                              }}
                            >
                              Send Request
                            </Button>
                          </div>
                        </div>
                      </Popup>
                    </div>
                    <div>
                      {/* <img
                        src={nearby.profile.vehicleImage}
                        style={{ width: "20rem" }}
                      /> */}
                      {/* <br></br>
                      <br></br> */}
                      <p style={{ fontWeight: "600" }}>Vehicle Information</p>
                      <br />
                      <p>
                        {nearby.profile.make} {nearby.profile.model} {nearby.profile.vehicleYear} -{" "}
                        {nearby.profile.specification}
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <br></br>
              </div>
            );
          })}
        </div>
      )}

      {selectedTab === 2 && tripRequest.status !== "Completed" && (
        <div>
          {favoriteDrivers.map((favDriver, idx) => {
            let covid = "";
            if (favDriver.profile.covid.wearMask === true) {
              covid += "I will wear a mask, ";
            }
            if (favDriver.profile.covid.riderWearMask === true) {
              covid += "I want the rider to wear a mask, ";
            }

            if (covid !== "") {
              covid = covid.slice(0, -2);
            }else{
              covid = "None"
            }

            let languages = "";
            if (favDriver.profile.languages.english === true) {
              languages += "English, ";
            }
            if (favDriver.profile.languages.arabic === true) {
              languages += "Arabic, ";
            }
            if (favDriver.profile.languages.spanish === true) {
              languages += "Spanish, ";
            }

            if (favDriver.profile.languages.other === "") {
              languages = languages.slice(0, -2);
            } else {
              languages += favDriver.profile.languages.other
            }

            return (
              <div key={favDriver.profile.id}>
                <div className="driver-box">
                  <div
                    className="split-body-equal-left-unpadded"
                    style={{ paddingLeft: "1rem", paddingBottom: "2rem" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <ReactRoundedImage
                          image={favDriver.profile.image}
                          roundedSize="0"
                          imageWidth="60"
                          imageHeight="60"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <p style={{ fontWeight: "600" }}>
                            {favDriver.profile.name}
                          </p>
                          {/* <Button style={{ textTransform: "none" }}> */}
                          <a href="" style={{ textDecoration: "none" }}>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#3579F6",
                              }}
                              onClick={()=>{history.push(`/driver-public-profile?driverID=${favDriver.profile.id}`)}}
                            >
                              View Profile
                            </p>
                          </a>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="form-row">
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {favDriver.profile.totalRides}
                          </p>
                          <p style={{ fontSize: "16px" }}>Total rides</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {favDriver.profile.years}
                          </p>
                          <p style={{ fontSize: "16px" }}>years</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {favDriver.profile.reviews}
                          </p>
                          <p style={{ fontSize: "16px" }}>reviews</p>
                        </div>
                        <div className="form-column-1">
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {favDriver.profile.rating}
                          </p>
                          <p style={{ fontSize: "16px" }}>ratings</p>
                        </div>
                      </div>
                      <br></br>
                      <br></br>
                      {favDriver.profile.certificateDate!==null && (<p><i>Completed timebank training course and obtained certificate on {favDriver.profile.certificateDate}</i></p>)}
                      <br></br>
                      <br></br>
                      <div className="form-row">
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Health-related safety preferences
                          </p>
                          <br></br>
                          <p>{covid}</p>
                          <br></br>
                        </div>
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Smoking preferences
                          </p>
                          <br></br>
                          <p>{favDriver.profile.smoke}</p>
                          <br></br>
                        </div>
                      </div>
                      <div className="form-row">
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Allergies
                          </p>
                          <br></br>
                          <p>{favDriver.profile.allergies}</p>
                          <br></br>
                        </div>
                        <div>
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Spoken Languages
                          </p>
                          <br></br>
                          <p>{languages}</p>
                          <br></br>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                        }}
                      >
                        {/* <Button
                          variant="contained"
                          onClick={handleDismiss}
                          style={{
                            color: "black",
                            background: "#091E4206",
                            textTransform: "none",
                          }}
                        >
                          Dismiss
                        </Button> */}
                        {tripRequest.status !== "Accepted" && <Button
                          variant="contained"
                          style={{
                            color: "white",
                            background: "#0C66E4",
                            textTransform: "none",
                          }}
                          onClick={() => {
                            openFavRequestPopup(idx);
                          }}
                        >
                          Request for a ride
                        </Button>}
                      </div>
                      <Popup
                        key={favDriver.profile.id}
                        openPopup={favRequestPopUp[idx]}
                      >
                        <div>
                          <p className="heading">
                            {" "}
                            Request {favDriver.profile.name} for a ride
                          </p>
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              paddingTop: "1rem",
                              paddingBottom: "3rem",
                            }}
                          >
                            {" "}
                            Check your desired trip and send request
                          </p>
                          <div className="driver-box">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "70%",
                              }}
                            >
                              <p>{tripRequest.date}</p>
                              <p>{tripRequest.time}</p>
                            </div>
                            <br></br>
                            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                              {/* TODO: */}
                              {tripRequest.pickup} to {tripRequest.destination}
                            </p>
                            <br></br>
                            <p>Nearby Pickup Intersection: {tripRequest.nearby_intersection}</p>
                            {/* <br></br>
                            <p> {tripRequest.purpose}</p> */}
                          </div>
                          <div
                            style={{
                              paddingTop: "5rem",
                              display: "flex",
                              flexDirection: "row",
                              gap: "60%",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                closeFavRequestPopup(idx);
                              }}
                              style={{
                                color: "black",
                                background: "#091E4206",
                                textTransform: "none",
                              }}
                            >
                              Close
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                background: "#0C66E4",
                                textTransform: "none",
                              }}
                              onClick={() => {
                                handleTripRequest(idx, favDriver.profile.id, closeFavRequestPopup);
                              }}
                            >
                              Send Request
                            </Button>
                          </div>
                        </div>
                      </Popup>
                    </div>
                    <div>
                      {/* <img
                        src={favDriver.profile.vehicleImage}
                        style={{ width: "20rem" }}
                      />
                      <br></br>
                      <br></br> */}
                      <p style={{ fontWeight: "600" }}>Vehicle Information</p>
                      <br />
                      <p>
                        {favDriver.profile.make} {favDriver.profile.model} {favDriver.profile.vehicleYear} -{" "}
                        {favDriver.profile.specification}
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <br></br>
              </div>
            );
          })}
        </div>
      )}

      {apiError && (
        <Alert severity="error">
          Request to server failed. Please try again
        </Alert>
      )}
      {/* {selectedTab === 2 && <div>{frequentDrivers}</div>} */}
    </div>
  );
};

export default AvailableDrivers;
