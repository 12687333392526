import { useEffect, useState } from "react";
import DriverProfileInfo from "./driverProfileInfo";
import DriverVehicleInfo from "./driverVehicleInfo";
import DriverPreferences from "./driverPreferences";
import DriverTripInfo from "./driverTripInfo";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Alert from "@mui/material/Alert";
import DriverEmergencyInfo from "./driverEmergencyInfo";

const DriverProfile = () => {
  const [page, setPage] = useState(0);
  const [nextButton, setNextButton] = useState("Next");
  const [driverID, setDriverID] = useState(0);
  const [user, setUser] = useState({});
  const history = useHistory();
  let bearerToken;

  try {
    bearerToken = window.sessionStorage.getItem("bearerToken");
    if (bearerToken === null) {
      history.push("/login");
    };
  } catch (e) {
    history.push("/login");
  }

  axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;

  const FormTitles = [
    "Primary Details",
    "Vehicle Information",
    "Emergency Contact Information",
    "Trip Information",
    "Preferences",
  ];
  const [profile, setProfile] = useState({
    id: 0,
    name: " ",
    pronoun: "",
    image: "",
    email: "",
    mobile: "",
    preferredCommunication: "Mobile",
    location: "",
    experience: 1,
  });

  const [vehicleInfo, setVehicleInfo] = useState({
    model: "",
    make: "",
    year: "",
    specification: "",
    numberPlate: "",
    image: "",
    courseTraining: "No",
    certificateDate: null,
    certificateExpiryDate: null,
  });

  const [covidPreference, setCovidPreference] = useState({
    wearMask: false,
    riderWearMask: false,
  });

  const [languages, setLanguages] = useState({
    arabic: false,
    english: false,
    spanish: false,
    other: ""
  });

  const [emergencyContact, setEmergencyContact] = useState({
    name: "",
    mobile: "",
    homePhone: "",
    email: "",
    preferredCommunication: "Mobile",
  });

  const [preferences, setPreferences] = useState({
    smoke: "I don't mind if the rider smokes",
    allergies: "Smoke",
    otherAllergies: "",
    music: "On",
    gasMoney: "Would be nice, but not necessary",
    maxTripDistance: "",
    maxNumberOfStops: "",
    maxTimeAvailable: "",
  });

  useEffect(() => {
    const getUserInfo = async () => {
      let id = window.sessionStorage.getItem("userID");
      let eID = window.sessionStorage.getItem("eID");
      let memID = window.sessionStorage.getItem("memID");
      setDriverID(id);

      await axios
        .get("https://iuhw-app.hourworld.org/user/" + memID + "?eID=" + eID, { headers: { "Authorization": "Bearer " + bearerToken } })
        .then((response) => {
          setUser({ ...response.data });
        })
        .catch((e) => {
          console.log(e);
        });
    };
    getUserInfo();
  }, []);

  function handleProfile(field, value) {
    setProfile({
      ...profile,
      [field]: value,
    });
  }

  function handleVehicleInfo(field, value) {
    setVehicleInfo({
      ...vehicleInfo,
      [field]: value,
    });
  }

  function handleEmergencyContact(field, value) {
    setEmergencyContact({
      ...emergencyContact,
      [field]: value,
    });
  }

  function handleCovidPreference(field, value) {
    setCovidPreference({
      ...covidPreference,
      [field]: value,
    });
  }

  function handleLanguages(field, value) {
    setLanguages({
      ...languages,
      [field]: value,
    });
  }

  function handlePreferences(field, value) {
    setPreferences({
      ...preferences,
      [field]: value,
    });
  }

  const [imageFile, setImageFile] = useState(null);

  function handleImageInput(value) {
    setImageFile(value);
  }

  const [vehicleImageFile, setVehicleImageFile] = useState(null);

  function handleVehicleImageInput(value) {
    setVehicleImageFile(value);
  }

  const [profileInfoAlert, setProfileInfoAlert] = useState("");
  const [vehicleInfoAlert, setVehicleInfoAlert] = useState(false);
  const [emergencyContactAlert, setEmergencyContactAlert] = useState(false);
  const [preferencesAlert, setPreferencesAlert] = useState(false);

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <div>
          <DriverProfileInfo
            profile={profile}
            handleProfile={handleProfile}
            imageFile={imageFile}
            handleImageInput={handleImageInput}
            languages={languages}
            handleLanguages={handleLanguages}
            vehicleInfo={vehicleInfo}
            handleVehicleInfo={handleVehicleInfo}
          />
          <br></br>
          {profileInfoAlert!=="" && (
            <Alert severity="error">{profileInfoAlert}</Alert>
          )}
        </div>
      );
    } else if (page === 1) {
      return (
        <div>
          <DriverVehicleInfo
            vehicleInfo={vehicleInfo}
            handleVehicleInfo={handleVehicleInfo}
            vehicleImageFile={vehicleImageFile}
            handleVehicleImageInput={handleVehicleImageInput}
          />
          <br></br>
          {vehicleInfoAlert && (
            <Alert severity="error">{"The vehicle fields make, model, year and license plate are required. Please provide valid values"}</Alert>
          )}
        </div>
      );
    } else if (page === 2) {
      return (
        <div>
          <DriverEmergencyInfo
            emergencyContact={emergencyContact}
            handleEmergencyContact={handleEmergencyContact}
          />
          <br></br>
          {emergencyContactAlert && (
            <Alert severity="error">{"Please provide valid values for the information provided"}</Alert>
          )}
        </div>
      );
    } else if (page === 3) {
      return (
        <div>
          <DriverTripInfo
            preferences={preferences}
            handlePreferences={handlePreferences}
          />
          <br></br>
          {preferencesAlert && (
            <Alert severity="error">{"The fields maximum distance, number of stops and duration available are required. Please provide valid values"}</Alert>
          )}
        </div>
      );
    } else {
      return (
        <DriverPreferences
          covidPreference={covidPreference}
          handleCovidPreference={handleCovidPreference}
          preferences={preferences}
          handlePreferences={handlePreferences}
        />
      );
    }
  };

  const handleBack = () => {
    setPage((currPage) => currPage - 1);

    if (page === 4) {
      setNextButton("Next");
    }
  };

  const manageDriver = async () => {
    preferences.covid = covidPreference;
    preferences.languages = languages;
    profile.id = driverID;
    profile.name = user.name;
    profile.email = user.emailID;
    profile.location = user.location;

    if (preferences.allergies === "Other") {
      preferences.allergies = preferences.otherAllergies;
    }

    if (vehicleInfo.courseTraining!=="Yes"){
      vehicleInfo.certificateDate=null;
    }

    const driverProfile = {
      profile: profile,
      vehicle: vehicleInfo,
      emergencyContacts: [emergencyContact],
      preferences: preferences,
    };

    await axios
      .post("https://iuhw-app.hourworld.org/driver", driverProfile)
      .then((response) => {
        window.sessionStorage.setItem("driverID", response.data.id);
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
        // history.push("/driver-home");
        return;
      });

    const imageFormData = new FormData();
    imageFormData.append("image", imageFile);

    await axios
      .post(
        "https://iuhw-app.hourworld.org/driver/" +
        driverID +
        "/upload-image",
        imageFormData
      )
      .then(() => {
        console.log("Uploaded driver image");
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
      });

    const vehicleImageFormData = new FormData();
    vehicleImageFormData.append("image", vehicleImageFile);

    await axios
      .post(
        "https://iuhw-app.hourworld.org/driver/" +
        driverID +
        "/upload-vehicle-image",
        vehicleImageFormData
      )
      .then(() => {
        console.log("Uploaded driver vehicle image");
        history.push("/driver-profile-preview");
      })
      .catch((e) => {
        //TODO: Add Alert
        console.log(e);
      });

      history.push("/driver-my-trips");
  };

  const handleNext = () => {
    if (page === 0) {
      if (
        profile.mobile === "" || !/^[0-9]{10}$/.test(profile.mobile)
      ) {
        setProfileInfoAlert("Mobile number is required. Please provide a valid 10 digit number without any extra characters.");
        return;
      }else if (profile.experience<0 || profile.experience>100){
        setProfileInfoAlert("Please enter a valid positive value for number of years of driving experience.");
        return;
      } else {
        setProfileInfoAlert("");
      }
    }
    else if (page === 1) {
      if (
        vehicleInfo.make === "" || vehicleInfo.year === "" ||
        parseInt(vehicleInfo.year)<=0 || vehicleInfo.model === "" ||
        vehicleInfo.numberPlate === ""
      ) {
        setVehicleInfoAlert(true);
        return;
      } else {
        setVehicleInfoAlert(false);
      }
    }
    else if (page === 2) {
      if (
        (emergencyContact.email!=="" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emergencyContact.email)) ||
        (emergencyContact.mobile!=="" && !/^[0-9]{10}$/.test(emergencyContact.mobile)) ||
        (emergencyContact.homePhone!=="" && !/^[0-9]{10}$/.test(emergencyContact.homePhone))
      ) {
        setEmergencyContactAlert(true);
        return;
      } else {
        setEmergencyContactAlert(false);
      }
    }
     else if (page === 3) {
      if (
        preferences.maxTripDistance === "" || parseInt(preferences.maxTripDistance)<=0 || parseInt(preferences.maxTripDistance)>1000 ||
        preferences.maxTimeAvailable === "" || parseInt(preferences.maxTimeAvailable)<=0 || parseInt(preferences.maxTimeAvailable)>24 ||
        preferences.maxNumberOfStops === "" || parseInt(preferences.maxNumberOfStops)<0 || parseInt(preferences.maxNumberOfStops)>5
      ) {
        setPreferencesAlert(true);
        return;
      } else {
        setPreferencesAlert(false);
      }
    }

    if (page>3){
      setPage(3);
    }

    setPage((currPage) => currPage + 1);

    if (page === 4) {
      manageDriver();
    } else if (page === 3) {
      setNextButton("Post your Ride Offer");
    }
  };

  return (
    <div className="profile">
      <div className="body">
        <p className="heading" style={{ fontWeight: "bold" }}>
          Profile Setup
        </p>
        <br />
        <p className="subHeading">
          Let's setup your profile to help drivers during the trip
        </p>
      </div>

      <div className="profile-home">
        <div className="driver-progressbar">
          <div
            style={{
              width:
                page === 0
                  ? "10%"
                  : page === 1
                    ? "30%"
                    : page === 2
                      ? "50%"
                      : page === 3
                      ? "80%"
                      : "100%",
            }}
          ></div>
        </div>
        <div className="inline-text">
          <div
            style={{
              color: page === 0 ? "#3579F6" : "black",
              marginLeft: "0%",
            }}
          >
            {FormTitles[0]}
          </div>
          <div
            style={{
              color: page === 1 ? "#3579F6" : "black",
              marginLeft: "6%",
            }}
          >
            {FormTitles[1]}
          </div>
          <div
            style={{
              color: page === 2 ? "#3579F6" : "black",
              marginLeft: "6%",
            }}
          >
            {FormTitles[2]}
          </div>
          <div
            style={{
              color: page === 3 ? "#3579F6" : "black",
              marginLeft: "6%",
            }}
          >
            {FormTitles[3]}
          </div>
          <div
            style={{
              color: page === 4 ? "#3579F6" : "black",
              marginLeft: "6%",
            }}
          >
            {FormTitles[4]}
          </div>
        </div>
      </div>
      <div className="form-container">
        <div className="form-body">{PageDisplay()}</div>
        <div className="form-footer">
          {page>0 && (<Button
            variant="contained"
            disabled={page === 0}
            onClick={handleBack}
            style={{
              color: "black",
              background: "#091E4206",
              textTransform: "none",
            }}
          >
            Back
          </Button>)}
          {page<=0 && <div></div>}
          <Button
            variant="contained"
            onClick={handleNext}
            style={{
              color: "white",
              background: "#0C66E4",
              textTransform: "none",
            }}
          >
            {nextButton}
          </Button>
        </div>
      </div>
      <br></br><br></br>
      <p>Please review our privacy policy <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/Privacy.pdf">here</a> and terms of service <a target="_blank" rel="noopener noreferrer" href="https://hourworld.org/pdf/TOS.pdf">here</a></p><br></br>
      <p><i>This ride service is part of National Science Foundation funded study and is approved by the University of Michigan institutional review board # HUM00166267</i></p>
    </div>
  );
};

export default DriverProfile;
